import {
  get_current_time,
  get_date_from_now,
  is_date_change,
  get_t,
  scroll_to_bottom,
  get_current_datetime,
} from "./erpnext_chat_utils";



export default class ChatPortalSpace {
  constructor(opts) {
    this.$wrapper = opts.$wrapper;  // chat container
    this.profile = opts.profile;
    this.chat_bubble = opts.chat_bubble;
    this.is_first_message = 1;
    this.setup();
    console.log("wrappppp", this.$wrapper, opts.container);
  }


  async setup() {
    const isMainPage = window.location.pathname === "/pages/index";
    console.log("ismain", isMainPage);
    // Ensure the chatbox exists
    const chatboxElement = document.querySelector(".chatbox");
    console.log("boxelement",chatboxElement);
    if (!chatboxElement) {
      console.log("Chatbox element not found. Ensure the '.chatbox' class exists in the DOM.");
    }
  
    // Add the chatbot-space class
    if (isMainPage){
      console.log("creating in the home");
      if (chatboxElement){
        this.$chatbot_space = $(chatboxElement).addClass("chatbot-space");

      }else {
        console.log("nobox");
      }
    }else{
      console.log("Not home page, creating a new div for chatbot space");
      this.$chatbot_space = $(document.createElement("div")).addClass("chatbot-space");
      $(this.$wrapper).append(this.$chatbot_space);
    }
    console.log("chatsace",this.$chatbot_space);
    
    
  
    // Setup other components
    try {
      this.setup_header(); // Assuming synchronous
      await this.setup_container(); // Asynchronous
      await this.setup_actions(); // Asynchronous
      this.setup_events(); // Assuming synchronous
      this.setup_socket(); // Assuming synchronous or handled internally
    } catch (error) {
      console.error("Error during setup:", error);
    }
  }

  setup_header() {
    const header_html = `
      <div class='chat-header'>
          <div class='chat-profile-info'>
              <div class='chat-profile-name'>
              ${erpnext_chat_app.res.chat_support_title}
              <div class='online-circle' style="background:#28a745"></div>
              </div>
          </div>    
  
          
      </div>
  `;
    this.$chatbot_space.append(header_html);
  }

  async setup_container() {
    this.$chatbot_container = $(document.createElement("div")).addClass(
      "chatbot-container"
    );
    this.$chatbot_space.append(this.$chatbot_container);
    if (this.profile.is_verified == 0) {
      const date_line = `
        <div class='date-line'>
            <div class="for_line">
              <span class="left-line"></span>
              <span class="between-lines">
                Today
              </span>
              <span class="right-line"></span>
            </div>
        </div>`;
      this.$chatbot_container.append(date_line);
      const init_message = `
          <div class="sender-message">
            <div class="message-bubble">${erpnext_chat_app.res.welcome_message}</div>            
          </div>
          `;
      this.$chatbot_container.append(init_message);
    } else {
      this.profile.respondent_user = await get_respondent_user(
        this.profile.room
      );
      const res = await get_messages(this.profile.room);
      await this.setup_messages(res);
    }
  }

  async setup_actions() {
    this.$chatbot_action = document.querySelector(".chat-actions")
    console.log("new actions",this.$chatbot_action)
    
    const chat_actions_html = `
        <div class="message-section">         
            <input class='form-control type-message' 
            type='search' 
            placeholder='${__("Type here")}'>
            <span class='message-send-button'>
                <svg xmlns="http://www.w3.org/2000/svg" width="1.1rem" height="1.1rem" viewBox="0 0 24 24">
                    <path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z"/>
                </svg>
            </span>
        </div>
        `;
    // this.$chatbot_action.html(chat_actions_html);
    this.$chatbot_space.append(this.$chatbot_action);
  }
// Here -------------------------------------------------------------------

setup_events() {
  const me = this;

  // Verify the $chatbot_action element
  if (!this.$chatbot_action || this.$chatbot_action.length === 0) {
    console.error("Chatbot action container is not initialized or found.");
    return;
  }

  // Event: Close chat window
  this.$chatbot_space.find(".close-chat-window").on("click", function () {
    console.log("closeclicked");
    me.chat_bubble.portal_chat_icon();
  });

  // Event: Send message button
  const sendMessageButton = this.$chatbot_action.querySelector(".send-message-button");
  console.log("thenewmessagebutton",sendMessageButton);
  if (sendMessageButton) {
    sendMessageButton.addEventListener("click", function () {
      console.log("found the new message send button");
      me.handle_send_message();
    });
  } else {
    console.error("Send message button not found.");
  }

  // Event: Enter key in type-message field
  const typeMessageField = this.$chatbot_action.querySelector(".type-message");
  console.log("found the typemessage field",typeMessageField);

if (typeMessageField) {
  typeMessageField.addEventListener("keyup", function (e) {
    if (e.key === "Enter" && !e.shiftKey) { // Modern way to check for Enter key
      e.preventDefault();
      me.handle_send_message();
    }
  });
} else {
  console.error("Type message field not found.");
}

}


  setup_socket() {
    const me = this;
    frappe.realtime.on(me.profile.room, function (res) {
      if(res.realtime_type == "send_message"){
        console.log("realtime type", res.realtime_type);
        me.receive_message(res, get_t(res.send_date));
      }
      
    });
  }

  render() {
    console.log("chatbotspaceafter appending ", this.$chatbot_space )
    console.log("wrapper before appending ",  this.$wrapper )
    // this.$wrapper.append(this.$chatbot_space);
  }

  async receive_message(res, time) {
    let chat_type = "sender-message";

    if (res.sender_email == this.profile.user_email) {
      chat_type = "recipient-message";
    }

    this.$chatbot_container.append(
      await this.make_message({
        content: res.content,
        time: time,
        type: chat_type,
        sender: res.user,
        message_name: res.message_name,
        message_template_type: res.message_template_type,
      })
    );
    console.log("receive message function");
    scroll_to_bottom(this.$chatbot_container);
    console.log("Received message:", res);

    this.prevMessage = res;
  }

  async setup_messages(messages_list) {
    await this.make_messages_html(messages_list);
    this.$chatbot_container.html(this.message_html);
    this.$chatbot_space.append(this.$chatbot_container);
  }

  async make_messages_html(messages_list) {
    this.prevMessage = {};
    this.message_html = "";
    for (const element of messages_list) {
      const date_line_html = this.make_date_line_html(element.send_date);
      this.prevMessage = element;
      this.message_html += date_line_html;

      let message_type = "sender-message";
      

      if (element.sender_email === this.profile.user_email) {
        message_type = "recipient-message";
      }

      const message_content = await this.make_message({
        content: element.content,
        time: get_t(element.send_date),
        type: message_type,
      });
      this.message_html += message_content.prop("outerHTML");
    }
  }

  make_date_line_html(dateObj) {
    let result = `
              <div class='date-line'>
                  <div class="for_line">
                    <span class="left-line"></span>
                    <span class="between-lines">
                      ${get_date_from_now(
                        dateObj,
                        "space",
                        this.profile.time_zone
                      )}
                    </span>
                    <span class="right-line"></span>
                  </div>
              </div>
          `;
    if ($.isEmptyObject(this.prevMessage)) {
      return result;
    } else if (
      is_date_change(
        dateObj,
        this.prevMessage.send_date,
        this.profile.time_zone
      )
    ) {
      return result;
    } else {
      return "";
    }
  }

  async make_message(params) {
    const { content, time, type } = params;
    const $recipient_element = $(document.createElement("div")).addClass(type);

    const $message_element = $(document.createElement("div")).addClass(
      "message-bubble"
    );

    let $sanitized_content = __($("<div>").html(content));

    $message_element.append($sanitized_content);
    $recipient_element.append($message_element);
    // $recipient_element.append(`<div class='message-time'>${time}</div>`);

    return $recipient_element;
  } //END make_message

  async handle_send_message() {
    if (this.$chatbot_space.find(".type-message").val().length == 0) {
      return;
    }

    let content = this.$chatbot_space.find(".type-message").val();
    console.log("contents", content);
    this.is_link = null;
    console.log("clikkkk");
    this.$chatbot_container.append(
      await this.make_message({
        content: content,
        time: get_current_time(),
        type: "recipient-message",
      })
    );
    console.log("clikkkks");
    scroll_to_bottom(this.$chatbot_container);

    const typeMessageField = this.$chatbot_action.querySelector(".type-message");

    if (typeMessageField) {
      typeMessageField.value = ""; // Clear the value
    } else {
      console.error("Type message field not found.");
    }
    // const text_content = content

    if (this.is_first_message == 1 && this.profile.is_verified == 0) {
      this.is_first_message = 0;
      const results = await create_guest_profile_and_channel(
        content,
        this.profile.user,
        this.profile.user_email,
        get_current_datetime()
      );
      localStorage.setItem("guest_token", results.token);
      this.profile.token = results.token;
      this.profile.room = results.room;
      this.profile.respondent_user = results.respondent_user;
      this.setup_socket();
    } else {
      const guest_message_info = {
        content: content,
        room: this.profile.room,
        sender: this.profile.user,
        sender_email: this.profile.user_email,
        send_date: get_current_datetime(),
        respondent_user: this.profile.respondent_user,
      };
      await send_message(guest_message_info);
      scroll_to_bottom(this.$chatbot_container); 
      }    
  } //End handle_send_message

} // END Class

async function create_guest_profile_and_channel(
  content,
  sender,
  sender_email,
  creation_date
) {
  const res = await frappe.call({
    method:
      "clefincode_chat.api.api_1_0_1.chat_portal.create_guest_profile_and_channel",
    args: {
      content: content,
      sender: sender,
      sender_email: sender_email,
      creation_date: creation_date,
    },
  });
  return await res.message.results[0];
}

async function send_message(params) {
  const { content, room, sender, sender_email, send_date, respondent_user } =
    params;
  const res = await frappe.call({
    method: "clefincode_chat.api.api_1_0_1.chat_portal.send",
    args: {
      content: content,
      room: room,
      sender: sender,
      sender_email: sender_email,
      send_date: send_date,
      respondent_user: respondent_user,
    },
  });
  return await res.message;
}

async function get_messages(room) {
  const res = await frappe.call({
    method: "clefincode_chat.api.api_1_0_1.chat_portal.get_messages",
    args: {
      room: room,
    },
  });
  return await res.message;
}

async function get_respondent_user(room) {
  const res = await frappe.call({
    method: "clefincode_chat.api.api_1_0_1.chat_portal.get_respondent_user",
    args: {
      room: room,
    },
  });
  return await res.message;
}



